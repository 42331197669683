<template>
  <div class="warning-box">
    <div class="head-name-title" style="margin-left: 20px">监控信息</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click.stop="hideDialog()" v-if="this.$getPermission('/pc/videos/save')">添加监控
      </el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="600px" height="434px" style="">
        <addmonitoring :greenHouseList="greenHouseList" :changeList="changeList"  :selectList="selectList" @rowList="addtabList"
                       @rowChangeList="changeListRow"></addmonitoring>
      </el-dialog>
      <el-table
          :data="tableData"
          border
          style="width: 950px;margin-top: 20px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号">
        </el-table-column>
        <el-table-column
            prop="name"
            label="监控名称">
        </el-table-column>
        <el-table-column
            prop="videosTypeName"
            label="所属类型">
        </el-table-column>
        <el-table-column
            prop="gname"
            label="所属大棚">
        </el-table-column>
        <el-table-column
            prop="url"
            label="监控url">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button
                type="text" size="small"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                type="text" size="small"
                @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import addmonitoring from '@/components/addmonitoring'

export default {
  components: {
    addmonitoring
  },
  props: {
    greenHouseList: Array,
  },
  data() {
    return {
      tableData: [],
      selectList:[],
      dialogVisible: false,
      optionList: [],
      value1: '',
      changeList: {},
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
    }
  },
  mounted() {
    this.getDataList()
    this.getSelectType()
    localStorage.setItem('selectIndex', '3')
  },
  methods: {
    hideDialog() {
      this.dialogVisible = true
      this.changeList = {}
    },
    getDataList() {
      this.$api.post('videos/getList', {
        "page": this.pageIndex,
        "size": this.pageSize,
      }, res => {
        console.log(res, '123')
        this.tableData = res.data.records
        this.totalPage = res.data.total
      });
    },

    getSelectType(){
      this.$api.get('videostype/select', {
      }, res => {
        console.log(res, '123')
        this.selectList = res.data
      });
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },


    changeListRow(data) {  //修改替换
      console.log(data)
      let idx = this.tableData.findIndex((item) => item.id === data.id)
      console.log(idx)
      this.tableData[idx] = data
      this.dialogVisible = false
    },
    addClickGreenHouse() {  //添加大棚按钮
      this.dialogVisible = true
      this.changeList = {}
    },
    handleEdit(index, row) {  // 修改按钮
      if (this.$getPermission('/pc/videos/update')) {
        this.changeList = []
        console.log(index, row.id);
        this.dialogVisible = true
        let idx = this.tableData.findIndex((item) => item.id === row.id)
        console.log(idx, 'idx')
        this.changeList = this.tableData[idx]
        console.log(this.changeList, 'this.changeList')
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/videos/delById')) {
      this.$api.post('videos/delById', {id: row.id}, res => {
        console.log(res, '删除', this.tableData)
        let idx = this.tableData.findIndex((item) => item.id === row.id)
        console.log(idx)
        this.tableData.splice(idx, 1)
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'success'
        });
      })
    }else{
      this.$message({
        showClose: true,
        message: '暂无权限',
        type: 'warning'
      });
    }
    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.tabLst-title {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1A99F2;
  cursor: pointer;
  margin-bottom: 10px;
}

.warning-box {
  background: #FFFFFF;

}
</style>
