<template>
  <div style="height: 404px;width: 400px;text-align: center;margin-left: 80px">
    <div class="dialog-title">{{ buttonText }}监控</div>
    <el-form :label-position="labelPosition" label-width="100px" >
      <el-form-item label="监控名称">
        <el-input v-model="dataForm.name"></el-input>
      </el-form-item>

      <el-form-item label="所属类型">
        <el-select v-model="dataForm.videosTypeId" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in selectList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属大棚">
        <el-select v-model="dataForm.gId" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in greenHouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通道号">
        <el-input v-model="dataForm.channelNo"></el-input>
      </el-form-item>
    </el-form>
    <el-col>
      <el-button class="dialog-button" @click="update">{{ buttonText }}</el-button>
    </el-col>
  </div>
</template>

<script>
export default {
  inject: [
    'reload'
  ],
  data() {
    return {
      dataForm:{
        name: '',
        channelNo: '',
        gId: '',
        videosTypeId:''
      },
      labelPosition: 'right',
      id:'',
      buttonText: '添加',
      newchangeList: []
    }
  },
  props: {
    greenHouseList: {
      type: Array,
    },
    changeList: {
      type: Object,
    },
    selectList: {
      type: Array,
    }
  },
  created() {
    this.newchangeList = JSON.parse(JSON.stringify(this.changeList))
  },
  mounted() {
    if (this.changeList.name) {
      this.dataForm.name = this.changeList.name
      this.dataForm.channelNo = this.changeList.channelNo
      this.dataForm.gId = this.changeList.gId
      this.dataForm.videosTypeId = this.changeList.videosTypeId
      this.id = this.changeList.id
      this.buttonText = '修改'
    } else {
      this.dataForm.name = ''
      this.dataForm.channelNo = ''
      this.dataForm.videosTypeId = ''
      this.dataForm.gId = ''
      this.id = ''
      this.buttonText = '添加'
    }
  },
  methods: {
    update() {
      if (this.id !== '') {  //修改
        this.dataForm['id'] = this.id
        this.$api.post('videos/update', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.$message({
              message: '新增成功',
              type: 'success',
              center: true,
              duration: 2000,
              onClose:()=>{
                //此处写提示关闭后需要执行的函数
                this.reload()
              }
            });

          }
        })
      } else {   //添加/pc/videos/save
        this.$api.post('videos/save', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    }
  },
  watch: {
    changeList: {
      handler: function (val) {
        if (this.changeList.name) {
          this.dataForm.name = val.name
          this.dataForm.channelNo = val.channelNo
          this.dataForm.gId = val.gId
          this.dataForm.videosTypeId = val.videosTypeId
          this.id = val.id
          this.buttonText = '修改'
        } else {
          this.dataForm.name = ''
          this.dataForm.channelNo = ''
          this.dataForm.gId = ''
          this.dataForm.videosTypeId = ''
          this.id = ''
          this.buttonText = '添加'
        }
      }
    }
  }

}
</script>

<style scoped>
.dialog-title{
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}
.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
